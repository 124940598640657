// COLUMNS

.col-3 {
    &__wrapper{
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: 1140px;
    }

    &__col {
        width: 30%;
        margin-right: 5%;
        @media only screen and (max-width: 678px) {
            width: 100%;
        }
    }
}

.margin-right{
    margin-right: 2rem;
}

.marg {
    &-a{
        &--sm {margin: 0.5rem;}
        &--med {margin: 1rem;}
        &--lar {margin: 2rem;}
        &--x {margin: 4rem;}
    }

    &-r{
        &--sm {margin-right: .5rem;}
        &--med {margin-right: 1rem;}
        &--lar {margin-right: 2rem;}
        &--x {margin-right: 4rem;}
    }
}

.padd {
    &-a{
        &--sm {padding: .5rem;}
        &--med {padding: 1rem;}
        &--lar {padding: 2rem;}
        &--x {padding: 4rem;}
    }

    &-r{
        &--sm {padding-right: 5rem;}
        &--med {padding-right: 1rem;}
        &--lar {padding-right: 2rem;}
        &--x {padding-right: 4rem;}
    }


}

// Padding

// BEFORE & AFTER PLACEMENTS
.bf {
    &-sm::before, &-sm::after {right:15px;}
    &-l::before, &-l::after {right:35px;}
}
// FLEX
.flex {
    &__container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
    }

    &--xs-left {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    &--inline {
        display: flex;

        &-left {
            &-2 {
                width: 100%;
            }
        }

        &-right {
            &-2 {
                width: 22rem;

                &x {
                    width:44rem;
                }
            }
        }
    }

    &--direction {
        &-col {
            flex-direction: column;
        }
    }
}

.alignment {
    &-items--center{
        align-items: center;
    }
}

.justify {
    &--sb {
        justify-content: space-between;

        &-i {
            justify-content: space-between !important;
        }
    } 

    &--c{
        justify-content: center;
    }
}

/*SPACING*/
.sp {
    &--btw {
        justify-content: space-between;
    }
}

/*BORDER RADIUS */
.br {
    &--small {border-radius: 4px;}
    &--medium {border-radius: 8px;}
    &--large {border-radius: 12px;}
    &--huge {border-radius: 20px;}
}

/*WIDTH AND HEIGHT*/
.w {
    &--100 {
        width: 100%;
    }
    &--8{
        width: 8rem;
    }
    &--5{
        width: 5rem;
    }
}
.width {
    &-100 {
        width:100%;
    }
}

.h {
    &--30 {
        height:30rem;
    }
}
/*BORDERS*/
.bor {
    &--grey {
        border: 1px solid $color-grey-light-1;
    }
}




.App-test {
    display: flex;
    flex-direction: row;
  }
  
  .todos-test {
    background: grey;
    height: 100px;
    width: 100px;
    margin: 10px;
  }
  
  .done-test {
    background: grey;
    height: 100px;
    width: 100px;
    margin: 10px;
  }

  .contentEditable {
    font-family: sans-serif;
    // margin-left: 10%;
    width: 100%;
    min-height: 100px;
    border: 1px dashed #aaa;
    padding: 5px;
    resize: none;
    border: 1px solid green;
  }

 
  .react-page-editable {
      width:75%;
      margin-left:10%;
  }

  .reactPage-MuiTypography-body1-137, .reactPage-MuiListSubheader-root-67, .reactPage-MuiInputBase-input-114{
      font-size: 1.6rem !important;
  }
  div[class^='reactPage-MuiTooltip-tooltip-'], div[class*='reactPage-MuiTooltip-tooltip-']{
    font-size: 1.2rem !important;
}
//reactPage-MuiIconButton-root-
  svg[class^='reactPage-MuiSvgIcon-root-'], svg[class*='reactPage-MuiSvgIcon-root-']{
    font-size: 2rem !important;
}


/* Table stylings */
table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}
table th {
    font-weight: normal;
    font-size: 1.3rem;
}
table tbody tr {
    height:5rem;
   
}

table tbody td {
    border: 1px solid #e3e7e9;
    font-size: 1.25rem;
    padding: 0.5rem;
}

table tbody td:nth-child(1) {
    text-align: left;
}

table tbody tr:nth-child(odd) {
    background-color: rgba(0,0,0,.05);
}

.spacer {
    height:6rem;
}

.text-align--center {
    text-align: center;
}

.flex-box {
    display: flex;

    &--column {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1140px) {
    .col-3 {
        &__wrapper{
            width:100%;
        }
    }
}


