.modalBox {
  //  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(darken($color-primary, 25%), 0.9); /* Black w/ opacity */

  &__content {
    color: #ededed;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    min-height: 30rem;
    transition: opacity 3s ease-in-out;
    animation: fadeIn .3s;
    -webkit-animation: fadeIn .3s;
    -moz-animation: fadeIn .3s;
    -o-animation: fadeIn .3s;
    -ms-animation: fadeIn .3s;
    display:flex;
    justify-content: space-between;
    align-content: center;
    overflow:hidden;

    &--close {
        color: $color-grey-light;
        float: right;
        font-size: 30px;
        font-weight: bold;
        padding: 0 .8rem;
        border-radius: 4px;
        margin: 1rem;

        &:hover, &:focus {
            color: $color-white;
            text-decoration: none;
            cursor: pointer;
            background-color: darken($color-primary, 5%);
        }
    }
    &--info{
        text-align: center;
        margin: 5rem 4rem;
        & h2 {
            font-family: "Lobster";
            font-weight: normal;
            font-size: 3.8rem;
        }

        & input {
            background-color: #fff;
        }
    }

    &--span {
        font-size: $font-size-small;
        font-style: italic;
    }
    & img{
        height: 100%;
        width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    &__content {
        flex-direction: column-reverse;
        & img{
            display:none;
        }

        &--info {
            margin: 0;
        }
    }

  }
}