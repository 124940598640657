.searchBar {
    font-size: $font-size-medium;
    border: 1px solid $color-primary;
    padding: .5rem 1rem .5rem;
    width: 100%;
    flex-wrap: nowrap;

    &:focus {
        outline: none;
    }

 }
  .header-search-wrapper {
        display: flex; 
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 15rem;
    margin-right: 2rem;
    }

@media only screen and (max-width: 768px) {
    .header-search-wrapper {
        display:none;
    }
}
