body:not(.single) .list-layout .post {
    max-width: 100%;
    background-color: $color-white;
    padding: 1rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $color-grey-light;
    //box-shadow: 0px 10px 5px -2px rgb(0 0 0 / 15%);
    
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 678px) {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 3rem 1rem;
    }
}

body:not(.single) .post, .single .post-entry, .single .post-entry-bottom, .post-share, #content .page, .post-author, .related-posts, .post-comments {
    margin-right: auto;
    margin-left: auto;
}

.cat a {
    color: inherit;
}

.container.sidebar-open .post-list.list .post-entry .post-entry-text {
    font-size: $font-size-medium;
    color:$color-grey-medium;
}

.list-layout {

    border-top: 4px solid #0076bf;
    
    & .categories-list{
        margin: 5rem 10rem;
    }

    & h1 {
        margin: 0 0 3rem;
    }
    & .post p {
        margin-bottom: 0;
    }

    & .post-featured-item {
        float: left;
        width: 360px;
        margin-right: 30px;
        margin-bottom: 0;
        overflow: hidden;

        &--img {
            -webkit-transform: scale(1);
            transform: scale(1);
            z-index: 1;
            -webkit-transition: transform 1s ease-in-out;
            transition: transform 1s ease-in-out;
        }

        &--img:hover {
            -webkit-transform: scale(1.15);
            transform: scale(1.15);
        }
    }

    & .post-header {
        text-align: left;
        margin-bottom: 5px;
        padding: 0;

        & .date-author {
            margin-top: 4px;
            color: $color-grey-medium;
            font-style: italic;
            font-size:$font-size-small;

            & p {
                padding: 0;
                display:flex;
                justify-content: space-between;
            }
        }
        
        
        & h2 a {
            color: inherit;
            -o-transition: .3s;
            -ms-transition: .3s;
            -moz-transition: .3s;
            -webkit-transition: .3s;
            transition: .3s;

            &:hover {
                opacity: .75;
            }
        }
            
    }

   
    & .post-entry-bottom a.custom-more-link {
        float: left;
        margin-bottom: 0;
    }
    & .post.has-post-thumbnail .post-entry-wrapper {
        float: right;
        width: 100%;
    }
    
}
.post.article-item {
    position: relative;
    margin-bottom: 4rem;
    display: flex;
}

.post-entry {
    position: relative;

    & p {
        margin-bottom: 20px;
    }

    &-bottom {
        margin-top: 30px;
        text-align: center;

        & a.custom-more-link {
            float: left;
            font-size: 16px;
            color: inherit;
            font-family: "Poppins";
            font-weight: 600;
            position: relative;
            padding:.75rem 2rem;
            border:1px solid $color-grey-light;

            &:hover {
                background-color: $color-primary;
                color: $color-white;
                text-decoration: none;
                border:1px solid $color-primary;
            }
        }
    }
}

.react-page-row {
    
    & figcaption {
    margin-bottom: 4rem;
    font-size: $font-size-medium;
    font-style: italic;
    }

    & code {
        background: #333;
        color: white;
        padding: 0.2rem;
    }

    & ul li:before {
        content: "\f0da"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        color: $color-secondary;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
    }
}

.post-featured-item {
    margin-bottom: 40px;
    position: relative;
    text-align: center;

    & a {
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    & img {
        display: block;
        height: auto;
        width: 100%;
        max-width: 87rem;
        max-height: 21rem;
        -o-transition: .3s;
        -ms-transition: .3s;
        -moz-transition: .3s;
        -webkit-transition: .3s;
        transition: .3s;
    }

    & .post-featured-item-inner {
        position: relative;
        display: inline-block;
        max-width: 100%;
        z-index:2;
        border-radius: 20px 20px 0 20px;
        overflow: hidden;

        & .cat {
            font-size: 1.2rem; color: white; background-color: $color-secondary; padding: 4px 6px ; font-weight: bold; position: absolute; bottom: 0; right: 0px;
        }
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 678px) {
     & img {
         height: 100%;
     }


    }
}

.post-header {
    margin-bottom: 45px;
    text-align: center;
    padding: 0 10%;

    & h1, & h2{
        margin-top: 0;
    }

    & h2 a{
        color: inherit
    }

    & .date-author {
        & .seperator {
            font-size: 10px;
        }
    
        & p {
            display: inline-block;
            padding: 0 10px;
            margin: 0;
        }
    }   
}

.post-list.list .post-entry-bottom {
    margin-top: 16px;
}

.sidebar-open .list-layout .post-featured-item {
    width: 22.5rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 678px) {
    .sidebar-open .list-layout .post-featured-item {
        width: 100%;
    }

    .list-layout .post.has-post-thumbnail .post-entry-wrapper {
        width: 100%;
        padding: 2rem;
    }
}

@media only screen and (min-width: 1400px) and (max-width:1649px){
    .sidebar-open .list-layout {
        & .post-featured-item {
            width: 27rem;
        } 
    } 

    .list-layout {
        & .post-featured-item {
            width: 40rem;
        }
        
        & .post.has-post-thumbnail .post-entry-wrapper {
            width: 69.5rem;
        }
    }   
}


@media only screen and (min-width: 1650px) {
    .sidebar-open .list-layout {
        & .post.has-post-thumbnail .post-entry-wrapper {
            width: 65rem;
        }
    } 
}