.bhAvatar {
    &__root{
        width: 4rem;
        height: 4rem;
        display: flex;
        overflow: hidden;
        position: relative;
        font-size: 2rem;
        align-items: center;
        flex-shrink: 0;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1;
        user-select: none;
        border-radius: 50%;
        justify-content: center;
    }

    &__color{
        &primary{
            color:#fff;
            background-color: $color-primary;
        }
        &secondary{
            color:#fff;
            background-color: $color-secondary;
        }
    }
}