.section {
    min-height:40rem;



    &-features {
        .section-features--1col {
           // background: url(/assets/img/street-1.jpg) center #000;
            background-size: cover;
            box-sizing: border-box;
            height: 50vh;
            margin-right: 0;
            top: 0;
            width: 45vw;
        }
    }

    &-about {
        background-color: $color-secondary;
        color: $color-white;

        .fa, .fas, .fab {
            color: $color-secondary;
            text-shadow: -1px -1px 0 lighten($color-secondary, 15%), 
                2px -1px 0 lighten($color-secondary, 15%), 
                -1px 1px 0 lighten($color-secondary, 15%), 
                2px 1px 0 lighten($color-secondary, 15%);
            font-size: 5rem;
            display: inherit;
        }

        span {
            font-size: 2rem;
        }
    }

    &-action-box {
        background: lighten($color-tertiary, 15%);
        color: white;
        text-align: center;
        font-size: 3rem;
        padding: 5rem 0;
    }
    &-reasons {

        h2 {
            font-size: $h2-size-font;
            & .callout {
                border-radius: 3px;
                color: $color-white;
                padding: .5rem;

                &--1 {
                    background-color:$color-tertiary;
                }
                &--2 {
                    background-color:$color-secondary;
                }
                &--3 {
                    background-color:$color-primary;
                }
            }
        }

        p {
            font-size: $paragraph-font;
            padding-left: 4rem;
            margin: 1.5rem 0 0 1.5rem;
        }
    }

    &-skills {
        ul > li {
            margin: 2rem 0;
            background-color: darken($color-grey-light-1, 5%);
            width: 35rem;
            height: 3rem;
            position: relative;
        }
        ul > li > span {
            background-color: #0096f2;
            color: #fff;
            display: inline-block;
            height: 3rem;
            justify-content: center;
            min-width: 10rem;
            padding: 0 .75rem;
        }

        .skill-set--level {
            background-image: linear-gradient(to right, $color-primary, darken($color-primary,15%));
            height: 100%;
            top: 0;
            left: 10rem;
            position: absolute;

            &--beginner {
                width: 25rem * .45;
                animation: fill-box 2s ease-in-out;
            }

            &--intermediate {
                width: 25rem * .65;
                animation: fill-box 2s ease-in-out;
            }

            &--advanced {
                width: 25rem * .85;
                animation: fill-box 2s ease-in-out;
            }

            &--expert {
                width: 25rem;
                animation: fill-box 2s ease-in-out;
            }
        }
    }

    &-bg-img {
        &--1 {
          //  background-image: url(/assets/img/bg-img--1.jpg);
            min-height: 550px;
            @include parallax;
        }

        &--2 {
         //   background-image: url(/assets/img/port-3.jpg);
            min-height: 500px;
            @include parallax;
        }
    }

}