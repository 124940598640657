body {
  position: relative;
  color: $color-grey-dark;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
}

.heading-primary {
  backface-visibility: hidden;
  color: $color-white;
  margin-bottom: 6rem;
  text-transform: uppercase;

  &--main {
      display: block;
      font-size: $h1-size-font;
      font-weight: 400;
      letter-spacing: 2.5rem;
      animation: appear 1s ease-in;
  }

  &--sub {
      display: block;
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: 1.25rem;
      animation: appear 5s ease-in;
  }
}

h1{font-size: $h1-size-font; font-weight: normal;

  & span {
    font-size: $h1-size-font;
    font-style: italic;
    color: $color-secondary;
  }
}
.h1-fs {font-size: $h1-size-font; font-weight: normal;}
h2{font-size: $h2-size-font; font-weight: normal;}
h3{font-size: $h3-size-font; color: $color-secondary;font-weight: normal;}
h4{font-size: $h4-size-font;font-weight: normal;}
h5{font-size: $h5-size-font;font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0;font-weight: normal;}

.heading-secondary {
  text-transform: uppercase;
  font-size: 3rem;
  letter-spacing: 2px;

  margin-bottom: 3rem;
  background-clip: inherit;
  background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
  color: transparent;
  -webkit-background-clip: text;

  &__color{
    //border-bottom: 1px solid $color-secondary;
    color: $color-secondary;
    display: inline-flex;
    margin-bottom:4rem;
  }
}

.heading-tertiary {
  font-size: $h3-size-font;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 3.5rem;
  text-transform: uppercase;
}

.heading-quad {
  font-size: $h4-size-font;
  font-weight: 700;
  text-transform: uppercase;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.navigation {
  font-size: 1.75rem;
  justify-content: space-between;
}

.paragraph {
  //font-size: $font-size-large;
  font-size: $paragraph-font;
  letter-spacing: 1.5px;
  line-height: 2.5;
  
  &:not(:last-child) {
      margin-bottom: 3rem;
  }

  &__color {
    background-clip: inherit;
    background-image: linear-gradient(to right, $color-primary, $color-secondary);
    color: transparent;
    font-size: 3rem;
    -webkit-background-clip: text;
  }

  &--note-size {
    font-size: $font-size-small;
  }
}

.weight {
  &--bold {
    font-weight: bold;
  }
}
.link {
  
  &--color{
    color: $color-primary-dark;
  }

  &--size-medium{
    font-size: $font-size-medium;
  }

  &--size-small {
    font-size: $font-size-small;
  }
  
  &--size-xx-small{
    font-size: $font-size-xx-small;
  }
}

.font {
  &--size-xlarge{
    font-size: $h1-size-font;
  }
  &--size-large{
    font-size: $font-size-large;
  }
  &--size-medium{
    font-size: $font-size-medium;
  }

  &--size-small {
    font-size: $font-size-small;
  }
  
  &--size-xx-small{
    font-size: $font-size-xx-small;
  }
}

.copyright {
  font-size: 1.3rem;
}
.logo-text {
  @include logo-text;
}

// COLORS
.color--white{
  color: $color-white;
}

.color--primary {
  color: $color-primary;
}

.error {
  border: 1px solid #e5c5c5;
  background-color: #efd7d7;
  padding: 1rem;
  margin: 1rem;
}

.italic_note { 
  font-size: $font-size-x-small;
  font-style: italic;
  margin-top: 1rem;
}

.MuiIconButton-label { color: $color-black;}


/* turning text into icons */
.icon {
  &-plus {

  }
}

/**** COLORS ***/
.color {
  &--grey {
    &-light{
      color: $color-grey-light;
    }
    &-dark {
      color: $color-grey-dark;
    }
  }

  &--secondary {
    color: $color-secondary;

    &-light {
      color: lighten($color-secondary, 25%);
    }
  }
  &--white {
    color: $color-white;
  }
  &--black {
    color: $color-black;
  }

  &--green {
    color:$color-green;
  }
}

.bg {
  &--color-black {
    background-color: $color-black;

    &_shade {
      background-color: $color-black-shade;
    }
  }
}

