.cta {
    &-heading{
        position: relative;
        height: 100vh;
    }

    &__text-box {
        position: absolute;
        top: 40%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }

    &__wrapper {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 2rem;

        & img {
            margin-right: 5rem;
        }

        &--widthbuilder{
            width: 100%;
        }
    }


    &__form {
        display: flex;
        width: 100%;

        &--wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &--input-wrapper{
            flex: .5;
            padding-right: 2rem;
        }
    }
}


@media only screen and (max-width: 768px) {
    .cta__wrapper, .cta__form{
        display: inline-block;
    }
}