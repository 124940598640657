.u-center-text {
    text-align: center;
}


.u-margin-bottom-small { margin-bottom: $s-s; }
.u-margin-bottom-medium { margin-bottom: $s-m; }
.u-margin-bottom-big { margin-bottom: $s-l; }
.u-margin-top-40 {margin-top: 40rem; }
.u-margin-top-big { margin-top: $s-l !important; }
.u-margin-top-medium { margin-top: $s-m !important;  }
.u-margin-top-small { margin-top: $s-s !important;  }
.u-margin-left-big{margin-left: $s-l}
.u-margin-right-big{margin-right: $s-l}
.u-margin-left-medium{margin-left: $s-m}
.u-margin-right-big{margin-right: $s-l}
.u-margin-right-medium{margin-right: $s-m}
.u-margin-right-small{margin-right:$s-s;}
.u-margin-vertical {margin:$s-l 0;}
.u-margin-left-1{margin-left:1rem}
.u-margin-right-1{margin-right:1rem}

.move-up {margin-top: -10vh;}
.move-right {margin-left: 10vw;}


.u-padding-8 {padding: 8rem 0;}
.u-padding-4 {padding: 4rem;}
.u-padding-2 {padding: 2rem;}

.cursor {
    cursor: pointer;
}

.slide-bg-effect {
    transition: background-position .3s cubic-bezier(.47, .1, 1, .63),
        color .2s linear;
    transition-delay: 0.25s, 0.35s;


    &:hover {
        background-position: -100% 100%;
        color: $color-white;
    }
}

.col-max-width {
    max-width: 75rem;
}

.clearfix {
    &:after{
        clear:both;
    }

    &::before, &::after{
        content: " ";
        display: table;
    }
}
.hideitem{
    display: none !important;
}
.hidebox-1 { 
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: 1;
    position: absolute;
}

.paperTheme {
    margin: 64px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.avatar {
    margin: 8px;
    background-color: $color-primary;
  }

.badge {
    border-radius: 2rem;
    background-color: $accent-admin-color;
    padding: .5rem 1rem;
    margin:auto;
    display: flex;
    width: fit-content;
    font-size:$font-size-small;
    color: $color-grey-light-1;
}

.border {
    &-bottom {
        &--black{ border-bottom:2px solid $color-black;}
    }

    &-top {
        &--grey {
            border-top: 1px solid $color-grey-light;
        }
    }
}

.no-border {
    border: none !important;
    & a {
        border: none !important;
        &:hover {
            border: none !important;
            background: none !important;
        }
    }
}

.hai {
    position:absolute;
    visibility: hidden;
}

.display-inherit {
    display: inherit;
}