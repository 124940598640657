.card {
    color: $color-white;
    background-size: cover;
    font-size: 2rem;
    height: 35rem;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
        background-size: cover;
        min-height: 35rem;
        width: 100%;
    }

    &__details {
        box-shadow: 1rem 1rem 2rem rgba(0,0,0, .15);
        bottom: 0;
        font-size: $paragraph-font;
        height: 10rem;
        padding: 1rem;
        position: absolute;
        right: 0;
        -webkit-transition: height 1s;
        transition: height 1s;
        width: 100%;

        &:hover{
            height: 15rem;
        }

        & a {
            color:$color-white;
            text-decoration: none;

            & span{
                font-size: 1.2rem;
                margin-bottom: 1rem;
                display:block;
            }

            & .card__details__viewlink{
                font-size: 1.3rem;
                margin-top: 4rem;
            }
        }

        &--1 {
            background-color: #0076BF;
        }

        &--2 {
            background-color: #8ABF45;
        }

        &--3 {
            background-color: #AB44BF;
        }

        &--4 {
            background-color: #C77332;
        }

        &--5 {
            background-color: #182CF2;
        }

        &--6 {
            background-color: #242959;
        }
    }

    &--settings {
        background: white;
        border: 1px solid #ccc;
        box-shadow: 1px 5px 13px 0px #dddddd78;
    }
}


.rankingCard {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem;
    border: 1px solid $color-grey-light;
    border-radius: 1rem;
    box-shadow: 2px 5px 11px rgb(0 0 0 / 15%);

    

    &-wrapper {
        display: flex;
        align-items: center;

        &:first-child {
            padding-bottom: 2rem;
            border-bottom: 1px solid $color-grey-light;
        }

        &:last-child {
            padding-top: 2rem;
        }
    }
    &-logo {

        & img {
            width: 100%;
            height: 100%;
            max-width: 20rem;
        }
    }

    &-siteInfo {
        min-width: 20rem;
        max-width: 45rem;
        padding: 0 2rem;
        font-size: 1.6rem;
    }

    &-listView {
        margin: 0 4rem 0 2rem;
        min-width: 20rem;

        & ul {
            padding-left: 0;;
        }
    }

    &-customScore {

        & p {
            font-size: $h3-size-font;
            color: $color-grey-light;

            & span {
                font-weight: bold;
                font-size: $h2-size-font;
    color: $color-black;
    padding: 0 0.5rem;
            }
        }
    }

    &-costBox {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
    
        &  ul {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }
        
        & span{
            color: $color-secondary;
            font-weight: bold;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .rankingCard {
        flex-direction: column;
        padding: 1rem 2rem;
        justify-content: center;

        &-siteInfo {
            max-width: 100%;
        }
    }
}