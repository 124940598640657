*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    // This defines what 1rem is
    font-size: 62.5%;
}

body {
    box-sizing: border-box;
    line-height: 1.4;
    font-size: 1.6rem;
}

html, body {
    height: 100%;
}
.nav-menu > li > a:hover, .nav-menu > li:hover > a, .nav-menu ul a:hover, .nav-menu ul ul a:hover, .top-search-area a:hover, .post-list.dynamic .post-header .cat, .post-list.full .post-header .cat, .single .post-header .cat, .post-list.dynamic .dynamic-promo-item-top .post-header .cat, .post-entry-bottom a.custom-more-link:hover, .like-comment-buttons a:hover, .related-posts .item .cat, .sub-meta-container, .list-layout .post-header .date-author, .pagination a:hover, #footer-copyright p i, .about-widget a:not(.widget-link), .widget.widget-with-thumbs .recent_post_text .post-category, .page-introduce-title strong, .page-introduce-title .search-query, #main-header .nav-menu .current-menu-ancestor > a, #main-header .nav-menu .current-menu-item > a, #main-header .nav-menu .current_page_item > a, .main-navigation-wrapper .nav-menu .sub-menu a:hover, .main-navigation-wrapper .nav-menu > li > a:hover, .main-navigation-wrapper .nav-menu > li:hover > a, .widget_archive a:hover, .widget_meta a:hover, .widget_categories a:hover, .widget_recent_comments a:hover, .widget_pages a:hover, .widget_text a {
    color: $color-black;//$color-primary
}
a {
    text-decoration: none;
    padding-bottom: 2px;
    transition: all 0.25s ease-in-out 0s;
    cursor: pointer;
    color: $color-primary;
   // border-bottom: 1px solid $color-primary;
}

a:hover {
    color: darken($color-primary, .1);
    text-decoration: underline;
    transition: ease-in-out .3s all;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}

ul > li {
    list-style: none;
    font-size: $font-size-small;
}

ul li, ol li {
    margin-bottom: 7px;
    line-height: 2;
    position: relative;
}
p, hr {
    margin-bottom: 20px;
}
p {
    line-height: 1.8;
}
main, section {
    // background-color: #e5ecE9;
}

input {
    border: none;
}

button {
    cursor: pointer;
}
::selection {
    color: $color-white;
    background-color: $color-primary;
}

.green {
    color: rgb(57, 133, 57);
}

.red{
    color: rgb(138, 53, 53);
}