// COLORS
$color-primary-light: #505155;//#7ed56f;
$color-primary-dark: #25303c;//#28b485;

$color-primary: #0076BF;//#55c57a;

$color-secondary: #8C8A0E;//lighten(#8C8A0E, 10%);//#8C8A0E;
$color-tertiary:#A65C00;//#CA1415;



$color-grey-light-1: #e7e7e7;
$color-grey-light: #ccc;
$color-grey-medium: #676767;
$color-grey-dark: #3B3C40;
$color-white: #fff;
$color-black: #0D0D0D;//#000;
$color-black-shade:#222222;

$color-green:  rgb(70, 173, 70);
$color-red:  rgb(173, 70, 70);

$color-bg-dark:#18203f;
$color-bg-light: #f8f9fa;
$accent-admin-color: blueviolet;
// FONTS

$font-family: "Segoe UI",Roboto,"Helvetica Neue",Arial,
"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

// FONT SIZES

$h1-size-font: 3.2rem;
$h2-size-font: 2.6rem;
$h3-size-font: 2.2rem;
$h4-size-font: 2.0rem;
$h5-size-font: 1.8rem;
$paragraph-font: 1.7rem;

$font-size-large: 2rem;
$font-size-normal: 1.85rem;
$font-size-medium: 1.5rem;
$font-size-small: 1.4rem;
$font-size-x-small: 1.2rem;
$font-size-xx-small: 1.0rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 4rem;
$gutter-horizontal: 3rem;

$s-l: 8rem;
$s-m: 4rem;
$s-s: 2rem;
$s-xs: 1rem;

