$thetransition: all .5s cubic-bezier(1, .25, 0, .75) 0s;

.site-nav {
    background: transparent;
    display: flex;
    justify-content: space-between;
    vertical-align: top;
    height:100%;

    &__item {
        line-height: 6rem;

        &--subnav {
            height: auto;
            line-height: 2rem;
            border-radius: 0 0 4px 0;
        }
    }

    &__link {
        background: transparent;
        display: block;
        padding: 0 10px;
        -webkit-transition: all .15s ease;
        -moz-transition: all .15s ease;
        transition: all .15s ease;
        font-size: 100%;
        height: inherit;
    }

    &__nav-toggle {
        display: block;
        font-size: $font-size-normal;
        font-weight: bold;
        letter-spacing: .1rem;
        color: $color-primary-dark;
        text-transform: uppercase;
    }

    &__hamburger {
        display: none;

        .bar {
            display: block;
            width: 25px;
            height: 3px;
            margin: 5px auto;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            background-color: #101010;
        }
    }

    &__bar {
        display: block;
        width: 2.5rem;
        height: .3rem;
        margin: .5rem auto;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-color: #101010;
    }
    &__acct {

        &--btn {
            background: darken($color-primary, 10%);
            color: white;
            padding: 5px 12px;
            border-radius: 20px;
            font-size: $font-size-medium;
            font-weight: 500;
            cursor: pointer;
            // margin-right: 40px;
        }

        &--subnav {
            position: absolute;
            background: #dddddd;
            // min-width: 100%;
            display: block;
            margin-top: 15px;
            right: 5px;
            padding-top: 7px;
            padding-bottom: 7px;
            border-radius: 3px;
            background-color: #fff;
            box-shadow: 0 0 0 1px #ccc;

            &::before,
            &::after {
                border-style: solid;
                content: "";
                height: 0;
                display: block;
                position: absolute;
                top: -8px;
                // right: 35px;
                width: 0;
            }

            &::before {
                top: -16px;
                border-color: transparent transparent #aaa transparent;
                border-width: 8px;
                right: 13%;
            }

            &::after {
                top: -15px;
                border-color: transparent transparent #fff transparent;
                border-width: 8px;
                right: 13%;
                // right: 35px;
            }

            &-link {
                padding: 8px 24px;
                font-size: 14px;
                color: #222;
                display: block;
                display: flex;
            

                &:hover {
                    color: $color-primary;

                }
            }

            &--header {
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1.5;
                border-bottom: 1px solid #ccc;
                padding: 16px;

                &--username {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: baseline;
                    font-size: $font-size-medium;
                }
            }

            &--subset {
                padding: 16px 0;
                border-bottom: 1px solid #ccc;
            }

            &--footer {
                display: flex;
                justify-content: space-around;
                font-size: $font-size-small;
            }

        }


    }

    @media only screen and (max-width: 768px) {

        &__list {
            position: absolute;
            left: -100%;
            top: 5rem;
            flex-direction: column;
            background-color: #fff;
            width: 100%;
            border-radius: 10px;
            text-align: center;
            transition: 0.3s;
            box-shadow:0 10px 27px rgba(0, 0, 0, 0.05);
            // background-color: #25303c;
            // left: 0;
            // overflow: hidden;
            // padding: 0;
            // position: absolute;
            // text-align: center;
            // top: 90px;
            // transition: all 0.2s ease;
            // width: 100%;
            // z-index: 4;
        }

        &__list.active{
            left:0;
        }

        &__link {
            margin: 2.5rem 0;
            //color: $color-grey-light-1;
            //border-bottom: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &__hamburger {
            display: block;
            cursor: pointer;

            &.active .bar:nth-child(2) {
                opacity: 0;
            }
        
            &.active .bar:nth-child(1) {
                transform: translateY(8px) rotate(45deg);
            }
        
            &.active .bar:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
            }
        }


    }

    @media (min-width: 768px) {


        &__item {


            width: auto;
            font-weight: bold;
            display: inline-block;

            &.-has-children {
                position: relative;
            }

            // &:after {
            //     transition: all ease-in-out .5s;
            //     background: none repeat scroll 0 0 $color-primary;
            //     content: "";
            //     display: block;
            //     height: 2px;
            //     width: 0;
            // }

            &:hover:after {
                width: 100%;
            }

            &--btn {
                width: auto;
                font-weight: bold;
                display: inline-block;
            }

            &--subnav {
                background: white;
                position: absolute;
                min-width: 150px;
                padding-top:1.5rem;
                box-shadow: 1rem 1rem 2rem rgba(0,0,0,.25);
                display: list-item;
                transition: all ease-in .3s;

                & li {
                    font-size: 1.6rem;
                    font-weight: normal;
                }
            }

            & i {
                font-size: 70%;
            }
        }

        &__link {
            padding: 0 20px;
            //font-size: $font-size-medium;
            display: inline-block;

        }

        &__item:hover &__link {
            background: transparent;
            color: $color-primary;
        }


        &__nav-toggle {
            display: none;
        }
    }
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 2.5rem;
}

