hr{
  
    border: 0;
    height: 1px;
    width: 100%;
    position: relative;
    background: $color-grey-medium;
    
    &.center-ball{
      background: $color-secondary;
      margin: 30px auto;
      width: 50%;

      &:before{
        content: "";
        width: 6px;
        height: 6px;
        background: $color-secondary;
        display: inline-block;
        border: 2px solid $color-secondary;
        border-radius: 50%;
        position: absolute;
        top: -4px;
        left: 50%;
        margin: 0 0 0 -3px;
      }
    }
}

span {
    &.center-ball {
        font-size: 14rem;
        position: absolute;
        top: -3.9rem;
        left: 50%;
        color: #8C8A0E;
        background-color: $color-white;
        display: flex;
        align-items: baseline;
        line-height: 0.25;
        padding-bottom: 10px;
        transform: translateX(-50%);
    }
}
    