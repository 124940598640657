.subscribe-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #fff;
    box-shadow: 2px 5px 20px rgb(0 0 0 / 15%);
  
    &--coat {
        margin-top: 8rem;
        border: 1px solid $color-primary;
    }
    &__title {
        font-weight: 700;
        display: flex;
        font-size: 2rem;
        margin-bottom: 1rem;
        justify-content: space-evenly;
        width: 100%;
        align-items: center;
    }
    &__thanks{
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #00768F;
        color: white;
        padding: 0 1rem;
        font-size: 1.4rem;
        justify-content: center;
    }
    &--form{
        display:flex;
        flex-direction: column;
        width: 100%;
        padding: 0 $s-s;
    }
    
    &__intro {
        margin-bottom: 20px;
        padding: 0 2rem;
    }
    &__input-wrapper {
        margin-right: 20px;
        width: 100%;
    }
    & input {
        border-radius: 0px;
        appearance: none;
        font-size: $font-size-medium;
        line-height: 3rem;
        padding: 0.5rem;
        // color: rgb(255, 255, 255);
        border: 3px solid $color-grey-light;
        width: 100%;
        background-color: transparent;

        &:hover {
            border: 3px solid $color-primary;
        }

        
        &[type=submit] {
            @include  secondary-color-submit-btn;
            width:8rem;
            background-color: $color-primary;
            width: 100%;
            padding: 1.2rem 0;
            margin: 2rem 0;
            
            &:hover {
                background-color: darken($color-primary, $amount: 5%);
                cursor: pointer;
            }
        }
    }

}