.header {
    position: fixed;
    z-index: 3;
    width: 100%;

    .social {position: fixed;}

    &-wrapper{
        display: flex;
        text-align: center;
        position: relative;
        
        &--logo {
            font-size: $h2-size-font;
            @include logo-text;
            padding-left: 10%;
          
            & a {
                color: $color-black;

                &:hover {
                    text-decoration: none;
                }
            }
            & span {
                color: $color-primary;
            }

            &-dash{
                @include logo-text;
                font-size: $h5-size-font;
                padding: 12px 10px 16px;
                & a {
                    color: $color-white;
                }
                & span {
                    color: $color-primary;
                }
            }
        }
        &--combiner{
            display:flex;
            align-items: center;
        }

        &-main-nav-wrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 0 2rem 0 1rem;
            align-items: center;
            z-index: 4;
            height: 6rem;
            width: 100%;
          //  text-align: center;
            background: #fff;
            box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
            -moz-box-shadow: 0px 1px 10px rgba(0,0,0,0.10);
            -webkit-box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -o-transition: height .3s;
            -ms-transition: height .3s;
            -moz-transition: height .3s;
            -webkit-transition: height .3s;
            transition: height .3s; 
        }
    }

    &--scroll {
        background-color: $color-white;
        border-bottom: 1px solid #E6EAEA;
        position: fixed;    
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        z-index: 4;
    }
    
    &-video-container {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        

        &::after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba($color-black, .45);
            
        }
        & video {
            /* Make video to at least 100% wide and tall */
            min-width: 100%;
            min-height: 100%;

            /* Setting width & height to auto prevents the browser from stretching or squishing the video */
            width: auto;
            height: auto;

            /* Center the video */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
    }
    }


    .down-button {
        bottom: 0;
        cursor: pointer;
        display: inline-block;
        height: auto;
        left: 0;
        margin: auto;
        right: 0;
        text-align: center;
        position: absolute;
        width: 90px;
        z-index: 4;

        h5 {
            color: $color-white;
        }

        .line {
            width: 1px;
            height: 50px;
            border-left: 1px solid #fff;
            margin: auto;
        }
    }

    &__logo-wrapper {
        position: absolute;
        top: 4rem;
        left: 4rem;
    }

    &__logo {
        height: 3.5rem;
    }

}

.main-container-wrapper {
    padding: 5.5rem 0;
    &--fullpage{
        margin-top: 8rem;
    }
}

.progress-bar {
    background: $color-primary;
    height: 2px;
    position: relative;
    width: 0%;
}
