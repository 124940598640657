.caption {
    font-size: $font-size-x-small;
    border: 1px solid $color-grey-light;
    padding: 0.4rem;
    background-color: $color-grey-light-1;

    & a {
        font-size: $font-size-x-small;
        border-color: $color-grey-light-1 $color-grey-light-1 $color-primary;
    }
}