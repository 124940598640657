.btn{
    border: none;
    
    &__link {
        border:none;
        background: transparent;
        color: $color-secondary;
    }
    &__root{
        color: inherit;
        border: 0;
        cursor: pointer;
        margin: 0;
        display: inline-flex;
        outline: 0;
        padding: 0;
        position: relative;
        align-items: center;
        user-select: none;
        border-radius: 0;
        vertical-align: middle;
        -moz-appearance: none;
        justify-content: center;
        text-decoration: none;
        background-color: transparent;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }
    &__search {
        background-color: $color-primary;
        border: none;
        padding: 0.75rem;
        color: white;
        transition: all 0.6s ease 0s;
        border-radius: 0 4px 4px 0;
        position: relative;
        left:-1rem;

        &:hover{
            background-color: darken($color-primary, $amount: 5%);
            cursor: pointer;
        }
    }
    &__social {
        width: fit-content;
        background-color: darken($color-primary, 5%);
        padding: .5rem .75rem;
        border-radius: 1rem;
        margin-right: .25rem;
        color: white;
        transition: all .3s ease 0s;

        &-active {
            background-color: lighten($color-primary,10%);
            box-shadow: 0px 4px 6px darken($color-primary, 5%) inset;
        }
    }
    &__sub{
        color: white;
        background-color: $color-primary;
        padding: 10px 10px 6px 10px;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid darken($color-primary, 5%);

        &:hover {
            background-color: darken($color-primary, 5%)
        }

        &--close{
            background-color: $color-primary;
            
            &:hover{
                background-color: darken($color-primary, 5%)
            }
        }
        &--large {
            padding: 1.25rem 2rem;
            font-size: $font-size-normal;
        }

        &--white {
            background-color: #f8f9fa;
            color: $color-primary;
            padding: 10px 10px 6px 10px;
            border: 1px solid #f8f9fa;

            &:hover{
                border: 1px solid $color-primary;
            }

            &--black {
                border: 2px solid black;
                color: $color-black;
                padding: 1rem;
                font-weight: bold;
                border-radius: 4px;

                &:hover{
                    border: 2px solid $color-primary;
                    background-color: $color-primary;
                    color: white;
                    transition: ease-in-out .3s;
                }
            }
        }
    }

    &__disabled {
        cursor: not-allowed;
        color: $color-grey-light;
        border: 1px solid $color-grey-light;

        &:hover{
            border: 1px solid $color-grey-light;
            color: $color-grey-light;
        }
    }
    &__fullWidth {
        width: 100%;
    }
}

.bhIconbtn{
    &__root{
        flex: 0 0 auto;
        color: rgba(0, 0, 0, 0.54);
        padding: 12px;
        overflow: visible;
        font-size: 1.5rem;
        text-align: center;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 50%;

        &:hover{background-color: rgba(0, 0, 0, 0.04);}
    }
    
    &__color{
        &primary{
            color:$color-primary;
            &:hover{
                background-color: rgba(63, 81, 181, 0.04);
            }
        }
        &secondary{
            color: $color-secondary;
        }
    }

    &__label{
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
    }
}

.txt-btn {
    background: none;
    border: none;
    color: $color-primary;
}