.blockquote {
    padding: 60px 80px 40px;
    position: relative;
}
.blockquote p {
    font-family: "Utopia-italic";
    font-size: 2.2rem;
    text-align: center;
}


.blockquote:before {
    position: absolute; 
    font-family: 'FontAwesome';
    top: 0;
    
    content:"\f10d";
    font-size: 200px;
    color: rgba(0,0,0,0.1);
     
  }
  
  .blockquote::after {
      content: "";
      top: 20px;
      left: 50%;
      margin-left: -100px;
      position: absolute;
      border-bottom: 3px solid $color-primary;
      height: 3px;
      width: 200px;
  } 