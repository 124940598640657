.related-articles-box {
    border-left: 1rem solid $color-primary;

    &__item {
        display: flex;
        margin-left: 2rem;
        align-items: stretch;
        
        & > div:first-child{
            margin-right: 10rem;
        }

        &--box{
            max-width:50%;
            display: flex;
            flex-direction: column;
        }

        & img {
            margin-right: 2rem;
            width: 10rem;
            height: 10rem;
            border-radius: 5rem;
        }

        & h3 {
            margin: 0;
        }
    }

    &--info {
        font-size: $font-size-small;
        margin-top:$s-s;
    }
}

@media only screen and (max-width: 940px) {
    .related-articles-box__item {
        flex-direction: column;
        width: 100%;

        & > div:first-child{
            margin-bottom: 10rem;
        }

        &--box {
            max-width: 100%;
        }
    }

    .related-articles-box--image, .related-articles-box--info {
        display: none;
    }
}