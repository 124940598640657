.fa-facebook {
    background-image: linear-gradient( to bottom, transparent 20%, white 14%, white 93%, transparent 93% ) !important;
    background-size: 39% !important;
    background-position: 50% -2px !important;
    background-repeat: no-repeat !important;
    &:hover {color: $color-white; background-color: #3B5998;}
}
.fa-google:hover {color: $color-white; background-color: #DB4437;}
.fa-twitter:hover {color: $color-white; background-color: #00acee;}
.fa-reddit:hover {color: $color-white; background-color: #FF4500;}
.fa-pinterest:hover {color: $color-white; background-color: #c8232c;}
.fa-youtube:hover {color: $color-white; background-color: #c4302b;}
.fa-tumblr:hover {color: $color-white; background-color: #34526f;}
.fa-quora:hover {color: $color-white; background-color: #b92b27;}
.fa-instagram:hover {color: $color-white; background-color: #CC3366;}
.social {
    z-index: 1;
    a {
        color: $color-white;
        border: none;
        border-bottom: none;

        &:hover {
            border: none;
            background: none;
            color: inherit;
        }
    }

    i {
        font-size: 3rem;
        margin-right: 1rem;
        border-bottom: none;
        color: $color-white;
        padding: .5rem;
        border-radius: 3px;

    
    }
    .fa-facebook {
        background-image: none !important;
    }

    ul {
        display: flex;
        justify-content: flex-start;
        padding: 0.5rem 1rem;
        text-align: center;
        width: 100%;
    }

    &--admin-inputs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;

    }

    &-textarea {
        width: 80%;
        height: 30rem;
        font-size: 1.85rem;
    }
}
