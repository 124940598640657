#sidebar {
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    min-height: 1px;
    max-width: 300px;
    float: right;
    border-left: 1px solid $color-grey-light;
    padding-left: 4rem;

    &-inner {
        position: relative;
    }

    & .widget {
        margin-bottom: 55px;
        position: relative;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-color: $color-white;

        &-title {
            position: relative;

            & span {
                position: relative;
                z-index: 1;
                display: inline-block;
                padding: 6px 15px;
                background-color: #fff;
            }

            &::before {
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 3px;
                content: '';
                background-color: #333333;
            }
        }

        &-topic--wrap {
            padding:1rem;
        }
    }
    
    @media only screen and (max-width: 768px) {
        & {
            display: none;
        }
    }
}

.theiaStickySidebar{
    padding-top: 0px;
    margin-top: 6rem;
    padding-bottom: 1px;
    position: static;
    top: 126px;
    left: 116.5rem;
}
