.admin {

    &-navigation {
        z-index: 2;
       // position: absolute;
        top: 0px;
        bottom: 0px;
        transition: -webkit-transform 0.3s ease-out 0s;
        will-change: transform;
       // overflow-y: auto;
        left: 0px;
        transform: translateX(0%);
        box-shadow: rgb(0 0 0 / 15%) 2px 2px 4px;

        &__sidebar {
            background-repeat: no-repeat;
            background-size: cover;
            // width: 25rem;
            background-position: top center;
            overflow: hidden;
            transition: all 200ms ease 0s;
            height: 100%;
            //width: 0; add this for hiding the navigation 

            &--content {
                position: relative;
                z-index: 0;
                padding-top: 64px;
            }

            & &--content::before {
                background-color: $color-grey-dark;
                position: absolute;
                min-height:100vh;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                z-index: -1;
                opacity: .9;
            }
        }
    }

    &__app {
        width: 100%;
        height: 100%;
            background-color: $color-bg-light;
    }

    &__container {
        
        &--nav-build {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            min-height: 100vh;
        }

        &-bch {
            display:flex;
            justify-content: space-between;
            padding: 0 2rem;
        }
    }

    &__main-container {
       flex-grow: 1;
        transition: left 0.3s ease-out 0s, right 0.3s ease-out 0s;

        &--controlBox-wrapper {
            display: flex;
            align-items: center;
            line-height: 4rem;
            justify-content: space-between;
            width: fit-content;

            & > *:not(:last-child) {
                margin-right:1rem;
            }

            & label {
                font-size: 1.2rem;
            }
        }
    }
    
    &-list__menu {
        margin-right: 0;

        &--label {
            color: white;
            font-size: $font-size-x-small;
            padding: 1rem 2rem;
        }

        &--title {
            color: #aaa;
            margin-left: 1rem;
            font-size: 1.4rem;
        }

        &--link {
            color: white;
            font-size: $font-size-small;
            display: flex;
            justify-content: space-between;
            padding: 0 1.5rem;

            &--container {
                display: inline-flex;

                & input {
                    margin: 16px;
                    display: inline;
                    padding: 0.75rem 1.25rem;
                    color: #222;
                }
            }

            &:hover {
                background-color: rgba(0,0,0,.25);
                color: $color-white;
            }

            &[title]:hover::after {
                content: attr(title);
                 position: absolute;
                top: 30px;
                left: 18px;
                font-size: $font-size-xx-small;
                background-color: orange;
                padding:2px 4px;
                border-radius: 4px;
              }
        }
        &--icon {
            margin-right: 1rem;
            min-width: auto;
            padding: 1rem 0;
        }
        &--item {
            font-size: $font-size-medium;
            margin-bottom: 0;
        }
    }


    /* ADMIN HEADER */

    &-header {
        &__container{}
        &__root{
            background-color:$color-bg-light;
            z-index: 99!important;
            position: relative;
        }

        &__toolbar{
            min-height: 64px;
            align-items: center;
            display: flex;
            justify-content: space-between;
            &--left {margin-left: 2rem; }
            &--right {margin-right: 2rem;}
            &--lii {
                display: inline-block;
                color: $color-grey-medium;
                padding: .1rem 1.1rem;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: .5rem
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    /* ADMIN CONTENT */
    &-content {
        &--container {
            padding: 2rem;

            &--header {
                position: fixed;
                z-index: 5;
                width: 75%;
                background: white;
                border: 1px solid #ccc;
                padding: 1rem;
                box-shadow: 4px 4px 5px rgb(0 0 0 / 25%);
                top: 0px;

                & button {
                    margin-right:10px;

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
    }

    /* ADMIN BUTTONS */

    &-IconButton {
        &--root {
            flex: 0 0 auto;
            color: rgba(0, 0, 0, 0.54);
            padding: 12px;
            overflow: visible;
            font-size: 1.5rem;
            text-align: center;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: 50%;
        }
    }

    &-list__menu--link.active {
        background-color: rgba(0,0,0,.45);
        border-left: 4px solid  $accent-admin-color;
    }
}

