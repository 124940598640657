.info-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80%;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 16px;
    z-index: 1;
  
    &.hidden {
      display: none;
    }
  
    .close-button {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 24px;
      font-weight: bold;
      color: #333;
      cursor: pointer;
      border: none;
      background: none;
    }
  }