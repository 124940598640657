#main-container {
    width: 100%;
    margin-bottom:8rem;
}

.container {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    
    & container-Xs {
        margin:0 auto;
    }
    
    &--width {
        width: 60%;
    }

    &--wrapper {
        display:flex;
    }

    &__aboutus{
        display: flex;
        flex-direction: column;
        margin: 4rem 20% 4rem 4rem;
    }
    
    &-Xs{
        max-width: 450px;
    }

    & #content {
        margin-right: 0 4rem;
        min-height: 75vh;
    }

    & .page-box{
        padding: 1rem 6rem;
        text-align: center;
        width: 110rem;
        margin-bottom: 10rem;
        border: 1px solid $color-primary;

        & .btn__sub {
            @include  secondary-color-submit-btn;
            padding: 1.5rem 2rem;
            background-color: $color-primary;
            
            &:hover {
                background-color: darken($color-primary, $amount: 5%);
                cursor: pointer;
            }
        }
    }

    & ol, & ul {
        padding-left: 6rem;
    }
}

.container.sidebar-open #content {
    width: 625px;
    float: left;
}

footer .col-3__wrapper {
    padding:0 2rem;
}

.header-wrapper--combiner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.post-list {
    width:100%;
}


.post-aside {
    //background-color: #fafafa;
    width: 30%;
    //border: 1px solid #ddd;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 678px) {
    .container {
        width: 100%;
    }

    .container.sidebar-open #content {
        width: 100%;
        padding-left: 2remW;
    }
    .sticky-TOC { display: none;}

    .post-aside {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) and (min-width: 601px) {
    .container {
        width: 620px;
        display: flex;
        margin: 0 5%;
    }

    .sticky-TOC { display: none;}

    
    .post-aside {
        display: none;
    }
}

@media only screen and (max-width: 860px) {
    .subscribe-box {
        display:none;
    }
 
    .post-aside {
        display: none;
    }

    .post-featured-item .post-featured-item-inner .cat {
        padding: 0 !important;
    font-size: 1.2rem !important;
    font-weight: normal !important;
    font-size: 1.2rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 678px) and  (max-width: 992px) {//and (min-width: 769px) {
    .container {
       // min-width: 675px;
        min-height: 385px;
        margin: 0 5%;

        & #content {
            margin-right:0;
        }
    }

    .post-aside {
        display: none;
    }
    
    .list-layout .content__category--header {
        margin-left: 4rem;
    }

    .sticky-TOC { display: none;}

    .container {
        width:auto;
    }

    .col-3__wrapper {
        width:auto;
    }

    .container.sidebar-open #content{
        width: 45rem;
    }
    .list-layout .post-featured-item {
        width: 100%;
    }
}

/* Phones */
@media only screen and (max-width: 768px) {
    footer .col-3__wrapper {
        display: none;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1399px) and (min-width: 993px){
    .container {
       // display: flex;
        min-height: 41rem;
        //margin: 0 15rem;
    }

    .container.sidebar-open #content{
        min-width: 67.5rem;
    }
}


/* Extra Extra large devices (large screens) */
@media only screen and (min-width: 1400px) and (max-width:1599px){
    .container {
        min-height: 50rem;
        display: flex;
    }

    .sticky-TOC { display: inherit;}
    .container.sidebar-open #content{
        min-width: 75rem;
        display: flex;
    }
}

@media only screen and (min-width: 1600px) {
    .container.sidebar-open #content {min-width: 95rem;}
}

