.feature-box {
    color: #fff;
        font-size: 17px;
        display: block;
        margin-top: 0;
        margin-bottom: 0;

    &-container {
        position: relative;
        top: -1px;
        margin-bottom:80px;
    }

    & ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: table;
        width: 100%;
    }

    & h4 {
        color: #fff;
        font-size: 17px;
        display: block;
        display: block;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.3;
    }
    
    & &__item {
        display: table-cell;
        width: 25%;
        margin-bottom: 0;
        text-align: left;
        vertical-align: top;
        background-color: $color-primary;
        overflow: hidden;
        -o-transition: background-color .3s;
        -ms-transition: background-color .3s;
        -moz-transition: background-color .3s;
        -webkit-transition: background-color .3s;
        transition: background-color .3s;

        &:nth-child(2) {
            background-color: #191919;
        }

        &:nth-child(3) {
            background-color: #3890dc;
        }

        &:nth-child(4) {
            background-color: #ce466a;
        }
    }

    & &__link {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
    }

    & &__item-text {
        position: relative;
        z-index: 1;
        padding: 10%;
    }

    &__item {
        &-content--promo {
            font-size: 11px;
            color: #fff;
            text-transform: uppercase;
            margin-top: 5px;
        }

        &-content--label {
            color: #fff;
    display: block;
    font-size: 60px;
    font-weight: 600;
    text-align: left;
    line-height: 1em;
    position: relative;
    left: -4px;
    margin-bottom: 25px;
        &:after{
            content: "";
            display: block;
            height: 3px;
            background-color: #fff;
            width: 75px;
            margin-top: 5px;
            position: relative;
            left: 6px;
        }

        }
        &-category {
            &--inner {}
        }
    }

    & &__item &__image {
        opacity: .251;
    }

    & &__item:hover &__image {
        opacity: .45;
        cursor: pointer;
    }


    & &__image {
        position: absolute;
        left: 0;
        top: -45px;
        width: 100%;
        height: 120%;
        background-position: center center;
        background-size: cover;
        -ms-transition: all .3s;
        -moz-transition: all .3s;
        -webkit-transition: all .3s;
        transition: all .3s;
    }

    
}

.vertical-middle {
    width: 100%;
    height: 100%;
    display: table;
    vertical-align: middle;

    &__inner {
        vertical-align: middle;
        display: table-cell;
    }

}


@media only screen and (max-width: 768px) {
    .feature-box {
        display: none;
    }
}
@media only screen and (min-width: 1680px) {
    .feature-box__item-content--label {
        font-size: 70px;
    }

    .feature-box h4 {
        font-size: 19px;//$h4-size-font;
    }
}