.contact {
    padding-top: 10rem;
    padding-bottom: 10rem;
    font-size: 1.6rem;

    &--card {
        box-shadow: 0px 10px 5px -2px rgb(0 0 0 / 15%);
        padding: 2rem; 
        display:flex; 
        align-items:center;
    }

    & .row{
        display: flex;
        justify-content: center;
    }
    &__topnote {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 4rem;
        
        & span {
            color: $color-secondary;
            text-transform: uppercase;
        }
        & p {
            margin-bottom: 0;
        }
    }
    &__form {
        width:100%;

        &__group:not(:last-child){
            margin-bottom: 2rem;
        }

        &__input{
            text-transform: uppercase;
            display: block;
            width: 100%;
            padding: 1.5em;
            background: transparent;
            border: 3px solid $color-grey-light;
            outline: none;
            font-size: .8em;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;

            &:focus {
                border-bottom: 3px solid lighten($color-primary, 15%);
                box-shadow: 0 1rem 2rem rgba($color-black, .1);
                outline: none;
    
                &:invalid {
                    border-bottom: 3px solid lighten($color-tertiary, 15%);
                }
            }
    
            &::-webkit-input-placeholder {
                color: $color-grey-dark;
            }

            
        }

        &__submitmessage {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid green;
            background-color: #72b372;
            color: white;
            margin-top: 4rem;
        }
        &__label {
            display: block;
            //font-size:1.2rem;
            text-transform: uppercase;
            font-weight: 700;
            margin: .7rem 0 0 2rem;
            transition: all .3s;
        }
    
        &__input:placeholder-shown + &__label { // ~ general sibling selector, + is for adjacent sibling
            opacity: 0;
            visibility: hidden;
            transform: translateY(-4rem);
        }

        &__textarea{
            text-transform: uppercase;
            display: block;
            width: 100%;
            padding: 1.5em;
            background: transparent;
            border: 3px solid $color-grey-light;
            outline: none;
            font-size: .8em;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }
}


.bhFormControlLabel {
    &__root {
        cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -11px;
    margin-right: 16px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    }
}

@media only screen and (max-width:678px) {
    .contact__form {
        width:auto;
    }

    .contact--card {
        flex-direction: column;
    }
}