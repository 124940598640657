.box {
    &--affiliate {
        border: 1px dashed $color-grey-light;
        padding: 1rem;
        font-size: $font-size-small;
        background-color: $color-grey-light-1;
        display: flex;
        flex-direction: column;
        align-items: center; 
    }

    &-content-message {
        max-width: 80rem;
        border: 4px solid $color-grey-light;
        background-color: $color-grey-light-1;
        padding: 4rem;

        ul li::before {
            content: "\f0da";
            font-family: FontAwesome;
            display: inline-block;
            color: #8c8a0e;
            margin-left: -1.3em;
            width: 1.3em;
        }
    }

}