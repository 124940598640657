@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

@mixin center-Hor-Vert {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin parallax {
      /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin logo-text {
    font-family: "Lobster";
    font-weight: 500;
    font-size: 2.4rem;
}

@mixin secondary-color-submit-btn {
    border: 0px;
    border-radius: 3px;
    font-size: $font-size-small;
    line-height: 1rem;
    transition: all 0.6s ease 0s;
    text-align: center;
    text-decoration: none;
    color: white;
    //background-color: $color-secondary;
}

@keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:.5;}
    100% {opacity:1;}
  }

  @keyframes fadeOut {
    0% {opacity:1;}
    50% {opacity:.5;}
    100% {opacity:0;}
  }  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:.5;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:.5;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:.5;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:.5;}
    100% {opacity:1;}
  }