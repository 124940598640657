footer {
    background-color: $color-black;
    width:100%;
    padding: 0 $s-s;

    .widget-title {
        font-size: 13px;
    }

    .connectWithList {
        margin-top: 40px;
    }
    .footer-links-wrapper {
        display: flex;
        background-color: black;
        justify-content: space-between;
        padding: 1rem;
    
        .footer-nav__link {
            padding-right: 1rem;
            font-size: $font-size-x-small;
        }    
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;

            li {
                font-size: $font-size-small;
                line-height: 2;
                margin: 0;
                padding: 0;
            }
        }
    }

    & p a {
        color: white;
        text-decoration: underline;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 678px) { 
    footer .footer-links-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .footer--large {
        display:none;
    }
}