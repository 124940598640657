.bhinput {


    &--full-width {
        width: 100%;
    }

    &__root {
        //color: rgba(0, 0, 0, 0.87);
        cursor: text;
        display: inline-flex;
        position: relative;
        font-size: $font-size-medium;
        box-sizing: border-box;
        align-items: center;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.1876em;
        letter-spacing: 0.00938em;
    }

    &--input {
        font: inherit;
        color: currentColor;
        width: 100%;
        border: 0;
        height: 1.1876em;
        margin: 0;
        display: block;
        padding: 0.7rem 1rem;
        min-width: 0;
        background: none;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        letter-spacing: inherit;
        animation-duration: 10ms;
        -webkit-tap-highlight-color: transparent;

        &::selection {
            color: #fff;
            background-color: $color-primary;
        }

        &:focus {
            outline: 0;
        }
    }

    &--admin {
        width:inherit;
        border: 1px solid $color-grey-medium;
        
    }
}

.bhinput-outlined {
    position: relative;
    border-radius: 4px;

    &.bhinput--focused &--notchedOutline {
        border-color: $color-primary;
        border-width: 2px;
    }

    &--input {
        padding: 18.5px 14px;
    }

    &--notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);

        &-root-7 {
            top: -5px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: 0 8px;
            overflow: hidden;
            position: absolute;
            border-style: solid;
            border-width: 1px;
            border-radius: inherit;
            pointer-events: none;

        }
    }

}

.bhinput-dark {
    &--input {
        color: white;
    }
}


/* FORM LABELS */

.bhformlabel {
    &__root {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: $font-size-medium;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
    }

    &--focused {
        color: $color-primary;
    }

    &-legendLabelled {

        &--9 {
            width: auto;
            height: 11px;
            display: block;
            padding: 0;
            font-size: 0.75em;
            max-width: 0.01px;
            text-align: left;
            transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
            visibility: hidden;

            &>span {
                display: inline-block;
                padding: 0 5px;
            }
        }

        &--outlined {
            max-width: 1000px;
            transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
        }
    }
}

.bhinputlabel {
    
    z-index: 1;   
    pointer-events: none;
    &__root {
        display: block;
        transform-origin: top left;
    }

    &--formControl {
        top: 0;
        left: 0;
        position: absolute;
        transform: translate(0, 24px) scale(1);
    }

    &--animated {
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }

    /* STYLES */
    &--outlined {
      //  transform: translate(14px, 20px) scale(1);
    }

    &--dark {
     //   transform: translate(14px, 10px) scale(1);
        color: $color-grey-light;
    }

    &--outlined.bhinputlabel--shrink {
        transform: translate(14px, -6px) scale(.75);
    }

    &--required {}

    /* Size */

    &--size-normal {
        transform: translate(14px, 20px) scale(1);
    }

    &--size-none {
        transform: translate(14px, 10px) scale(1);
    }
}

.bh-checked {

}
.bhCheckbox {
   
    &__color{
        &primary{
            color:$color-primary;

            &.bh-checked{
                color: $color-primary;
                &:hover{
                    color: $color-primary;
                }
            }
        }
        &secondary{
            color: $color-secondary;
        }
    }

    &__root{
        color: rgba($color-black, .54);
    }
}


/* FORM CONTROLS */
.bhFormControl {
    &__root {
        border: 0;
        margin: 0;
        display: inline-flex;
        padding: 0;
        position: relative;
        min-width: 0;
        flex-direction: column;
        vertical-align: top;
    }
    &Label {
        &__root{
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            margin-left: -11px;
            margin-right: 16px;
            vertical-align: middle;
            -webkit-tap-highlight-color: transparent;
        }

    }
    &--margin-normal {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    &--full-width {
        width: 100%;
    }
}

.bhSvgIcon{
    &__root{
        fill: currentColor;
        width: 2.5rem;
        height: 2.5rem;
        display: inline-block;
        font-size: 2.5rem;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
    }
}
//MuiTextField-root 
