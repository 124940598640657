@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'base/animations';
@import 'base/base';
@import 'base/layout';
@import 'base/reset';
@import 'base/typography';
@import 'base/utilities';

@import 'layout/admin';
@import 'layout/footer';
@import 'layout/grid';
@import 'layout/header';
@import 'layout/content';
@import 'layout/navigation';
@import 'layout/sidebar';

@import 'components/avatar';
@import 'components/blockquote';
@import 'components/blog-post';
@import 'components/box';
@import 'components/buttons';
@import 'components/caption';
@import 'components/card';
@import 'components/composition';
@import 'components/ctaheader';
@import 'components/customDivider';
@import 'components/feature-box';
@import 'components/featurette-posts';
@import 'components/form';
@import 'components/infobox';
@import 'components/input';
@import 'components/modal';
@import 'components/pagination';
@import 'components/relatedpost';
@import 'components/search';
@import 'components/socialbox';
@import 'components/social-links';
@import 'components/stickTOC';
@import 'components/subscribe-box';
@import 'components/widgets';

@import 'pages/home';
@import 'pages/contact';


/* cyrillic */
@font-face {
    font-family: 'Neucha';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bfuQltOxU.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
/* latin */
@font-face {
    font-family: 'Neucha';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bfvQlt.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Open Sans Light';
    src: local('Open Sans Light'), url('../assets/fonts/OpenSans-Light.ttf');
}

@font-face {
    font-family: 'Open Sans Regular';
    src: local('Open Sans Regular'), url('../assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Lobster';
    src: local('Lobster'), url('../assets/fonts/Lobster-Regular.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../assets/fonts/Roboto-Regular.ttf');
}