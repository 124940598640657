.widget {
    margin-bottom: 40px;
    font-size: 14px;
    
    & li, & a {
        color: inherit;
    }

    & ul {
        padding: 0px 1rem 1rem 1rem;
        margin: 0;
        
        & li {
            line-height: 1.6;
            list-style-type: none;
        }
    }

    &-title {
        text-transform: uppercase;
        margin: 2rem 0.25rem;
    }
    
    .topic {    
        font-size: 10px !important;
        font-weight: 600;
        text-transform: uppercase;
        margin-right: 5px;
        margin-bottom: 7px;
        display: inline-block;
        text-decoration: none;
        padding: 7px 10px 6px;
        color: inherit;
        border: 1px solid lighten($color-grey-dark, 10%);

        &:hover {
            border-color: transparent;
            background: $color-primary;//#fc7f6f;
            color: #fff;
            transition: ease-in-out .3s;
        }

        & a {
            text-decoration: none;
        }
    }

    &--column {
        flex-direction: column;
        align-items: baseline;
    }
}

.widget.widget-with-thumbs {
    & .thumb.size_50_50, & img {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        -o-transition: .3s;
        -ms-transition: .3s;
        -moz-transition: .3s;
        -webkit-transition: .3s;
        transition: .3s;
    }

    & ul li {
        margin-bottom: 20px;
        line-height: 1.6em;
        position: relative;
        border: none;
    }

    & .thumb.size_50_50 {
        float: left;
    
        & a {
            display: block;
            position: relative;
            overflow: hidden;
        }
    }

    & .recent_post_text {
        display: block;
        padding-left: 100px;
        padding-top: 7px;
    
        & .post-category {
            color: $color-secondary;
            font-size: 11px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 5px;
        }

        & a {
            font-size: $font-size-medium;
            font-family: "Poppins";
            display: inline-block;
            line-height: 1.4;
            color: inherit;
            letter-spacing: -0.0125em;

            &:hover {
                color: $color-primary;
                transition: ease-in-out .25s;
            }
        }

        & h4 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
 
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 678px) { 
    .adPlaced {
        width:100%;
        display: none;
    }
}







