.row {
    height: 100%;
    margin: 0 auto;
    max-width: $grid-width;
    padding: 0 5rem;

    &:not(:last-child) {
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

    [class^="col-"] {
        float:left;

        &:not(:last-child) {
            margin-right: $gutter-horizontal;
        }
    }

    &--override-max-width {
        max-width:100%;
    }
    .col-1-of-2 {
        width: calc((100% - #{$gutter-horizontal}) / 2);
        @media only screen and (max-width: 678px) {
            width: 100%;
        }
    }

    .col-1-of-3 {
        width: calc((100% - (#{$gutter-horizontal} * 2)) / 3);
    }

    .col-2-of-3 {
        width: calc(2 * ((100% - (#{$gutter-horizontal} * 2)) / 3) + #{$gutter-horizontal});
    }

    .col-1-of-4 {
        width: calc((100% - (#{$gutter-horizontal} * 3)) / 4);
    }

    .col-2-of-4 {
        width: calc( 2 * ((100% - (#{$gutter-horizontal} * 3)) / 4) + #{$gutter-horizontal});
    }

    .col-3-of-4 {
        width: calc( 3 * ((100% - (#{$gutter-horizontal} * 3)) / 4) + (2 * #{$gutter-horizontal}));
    }
}