.blog-post {
    width: 100%;

    &__header-image{
        width: 100%;
        height:35rem;
        overflow: hidden;

        & img{
            transform: translateY(-25%); 
            width:100%;
        }
    }

    &__article-content {
        padding: 8rem 0;
        width: 100%;
        max-width: 75rem;

        &-author { 
            display: inline-block;
            border-bottom: 1px solid $color-grey-light;
            width: 100%;

            &--avatar{ 
                width: 50px;
                height: 50px;
                overflow: hidden;
                border-radius: 200px;
                margin-right: 20px;

                &--homepage {
                    width: 10rem;
                    height: 10rem;
                    border-radius: 200px;
                    margin: 2rem 2rem 0 0;
                    float:left;
                }
            }
            & ol {
                margin-left: 4rem;
            }
            
            & ul {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 0;

                & li {
                    display: inline-block;
                    width: auto;
                    margin-right: 20px;
                    font-size: $font-size-medium;
                }
            }

            @media only screen and (max-width: 768px) {
                & ul {
                    flex-direction: column;
                }
            }
           
        }

        &-text {
            margin: 40px 0;

            & h2{
                margin: 1.5rem 0;
            }

            & h3 {
                margin: 4rem 0px 2rem;
            }
        }
        & h3 a{
            border:none;
            border-bottom: none;
    
            &:hover{
                border: none;
                border-bottom: none;
            }
        }

        & a {
            text-decoration: none;
            padding-bottom: 2px;
            transition: all .25s ease-in-out 0s;
            cursor: pointer;
            border: 3px solid white;
            border-bottom: 3px solid #0076bf;
            color: #333;

            &:hover {
                color: white;
                transition: all .3s ease-in-out;
                background: #0076bf;
                border: 3px solid #0076bf;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &__header-image{
            display: none;
        }

        &__article-content {
            padding:8rem 2rem;
        }
    }
    
}

.tCB{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: auto;
    padding: 1rem;
    background-color: #e7e7e7;
    border: 2px solid #ccc;
    margin: 1rem 0.5rem;

    &__inner--wrap {
        justify-content: space-between;
        padding: 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        border: 1px #aaa solid;
    }

    &__best{
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        &--wrap {
            padding: 1rem;
            border-radius: 999rem;
            background-color: $color-grey-light;
            text-align: center;

            & a {
                color: black;
                cursor: pointer;
                padding: calc(0.667em + 2px) calc(1.333em + 2px);
                font-size: 1.4rem;
                font-weight: bold;
                border: none;

                &:hover {
                    border: none;
                }
            }
        }
    }

    &__PIU {
        margin: 0 0 1rem;

        &--wrap {
            margin-left: auto;
            margin-right: auto;
            display: table;

            &--2 {
                
                img {
                    border-radius: 999rem;
                    width: 8rem;
                    max-width: 100%;
                    height: 8rem;
                    margin-bottom: 0px !important; 
                }
            }
        }
    }
    
    &__title{
        font-weight: bold;
    }

    &__para{
        font-size: $font-size-small;
    }

    &__SU{
        display: flex;
        gap: 0.5em;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        &--wrap {
            background-color: #0076BF;
            border-radius: 2rem;
            color: #fff;
            font-size: 1.8rem;
            display: inherit;

            a {
                color: white;
                padding: calc(0.667rem + 0.25rem) calc(1.333rem + 0.25rem);
                text-align: center;
                border: none;

                &:hover{
                    border:none;
                }
            }
        }
    }
    &__RA{
        text-align: center;
        margin-top: 2rem;
    }
}