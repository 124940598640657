.sticky-TOC {
    position: fixed;
    right: 0;
    top: calc(20% + 30px);
    z-index: 4;
    transform: translateY(-50%);
    width: calc(15% + 30px);
    //max-width: 215px;
    list-style: none;
    padding: 0px;
    margin: 0px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 10px 0 0 0;

    & li {
        position: relative;
        transform: translate3d(5%, 0px, 0px);
        opacity: 0;
        transition: all 0.3s ease-in-out 0s;

        &:nth-child(1) {
            transition-delay: 0.05s;
        }
    }
    & h4 {
        text-align: center;
        margin: 1rem auto;
        border-radius: 10px 0 0 0;
    }

    &.not-visible {
        opacity: 0;
        transform: translate3d(0px, 0px, 0px);
    }

    &.is-visible {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
        padding-left: 0rem;
        z-index: 1;
    }
    
    &.is-visible li {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
    }

    &.sticky-TOC a {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
        color: #aaa;
        border: none;
        padding-left: 7rem;
        margin-bottom: 30px;
        transition-timing-function: ease-in-out;
    
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            left: 0px;
            width: 50px;
            height: 1px;
            background: #aaa;
        }
        &:hover {
            color:$color-white !important;
        }
        &.active {
            color: $color-primary;
            transform: translateX(20px);  
        }

        &.active::before {
            background: $color-primary;
            width: 7.5rem;
            left: -2rem;
        }
    }
}