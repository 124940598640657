.composition {
    position: relative;

    &__photo {
        border-radius: 2px;
        box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
        outline-offset: 2rem;
        position: absolute;
        transition: all .2s;
        width: 55%;
        z-index: 3;

        &--p1 {
            left: 0;
            top: -2rem;
        }

        &--p2 {
            right: 0;
            top: 2rem;
        }
        &--p3 {
            left: 20%;
            top: 10rem;
        }

        &:hover {
            box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
            outline: 1rem solid $color-primary;
            transform: scale(1.05) translateY(-5px);
            z-index: 4;
        }
    }
    &:hover &__photo:not(:hover) {
        transform: scale(.95);
    }
}